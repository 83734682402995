
import styled from "styled-components"

export const Container = styled.div`
    width: 90%;
    max-width: 1080px;
    margin: 0 auto;
    // @media (max-width: 768px) {
    //     padding-top: 60px;
    // }
`;