import React from 'react';
import styled from 'styled-components'
import { FadeIn } from '../../theme/fadeIn'
import { Container } from '../container'

const Info = styled.div`
  text-align: center;
  font-size: 13px;
  color: ${ props => props.theme.title };
`;

const Border = styled.div`
  margin-top: 20vh;
  border-top: 1px solid ${ props => props.theme.line };
  border-bottom: 1px solid ${ props => props.theme.line };
  padding: 35px 0;
  text-align: center;
`;

const BtnSave = styled.button`
  background: none;
  padding: 7px 12px;
  margin: 20px auto;
  border: 1px solid #fff;
  padding: 15px;
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  &:hover {
    background: ${ props => props.theme.button };
    color: ${ props => props.theme.buttonHover };
    cursor: pointer;
  }
`;

const ImgLogo = styled.img`
  width: 90px;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const WaitSave = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(10,10,10,0.95);
  text-align: center;
  padding-top: 45vh;
  z-index: 100;
`;

  export class SaveIt extends React.Component {
    constructor(props) {
      super(props);

      this.state = {  
        dataUser: '',
        rightUrl: '',
        waitSave: false
      };
  
    }

    redirectToLogin(link) {
      localStorage.removeItem('tokenAuth');
      document.location.href = '/?r=save&link=' + link;   
    }


    fetchUserData() {
      fetch(process.env.REACT_APP_API_URL + '/user', { method: 'GET', headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('token')} })
      .then( (res) => {
        if (res.status !== 200) {
          this.redirectToLogin(this.state.rightUrl)  
        }
        return res.json()
      })
      .then(json => this.setState({ dataUser: json[0] }))
      .catch( (err) => {
              console.log('Error: ' + err);
              this.redirectToLogin(this.state.rightUrl)
            } 
      );
    }

    addLink() {
      let self = this;

      if (this.state.rightUrl !== '') {
        this.setState({ waitSave: true })
        fetch(process.env.REACT_APP_API_URL + `/bookmark/add`, { method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('token')}, body: JSON.stringify({ "url": this.state.rightUrl}) })
        .then(function(response) {
            console.log(response.status); // Will show you the status
            if (response.status === 201 || response.status === 200) {
              self.setState({ waitSave: false })
              window.location.href = "/unassigned";
            } else {
              self.setState({ waitSave: false })
              window.alert("Save share failed!");              
            }
        })
        .catch(function(error) {
            self.setState({ waitSave: false })
            window.alert("Save share failed!");
            console.log('There has been a problem with your fetch operation: ', error.message);
        });
      } else {
        window.alert("Error link!");
      }
    }
    
    componentDidMount() {

        let url = window.location.pathname;
        let rightUrl = url.replace('/save/', '')

        // without url
        if (rightUrl === '/save') {
          rightUrl = '';
        }

        // add https to string
        if (rightUrl !== '' && rightUrl.indexOf('http') === -1) {
          rightUrl = 'https://' + rightUrl;
        }

        this.setState({
            rightUrl: rightUrl
        })

        document.title = "Save in bookmark | zxccv.xyz";
        this.fetchUserData();
      }

    render() {
    return (
        <>
        <FadeIn>
          <Border>
            <Container>
              <ImgLogo src="https://micro-zxccv.netlify.app/favicon.ico" alt="logo" border="0"></ImgLogo>
              <Info>Just type app.zxccv.xyz/save/ in front of any URL to save a bookmark to the cloud. It just works... everywhere!</Info>
                { this.state.waitSave && <WaitSave> <img alt='save...' src="https://samherbert.net/svg-loaders/svg-loaders/tail-spin.svg"></img> </WaitSave> }
                <BtnSave onClick={ () => { this.addLink() }}>Save this link!</BtnSave>
                <p>account: {this.state.dataUser.name} <br />
                link: {this.state.rightUrl}</p>
            </Container>
          </Border>
        </FadeIn>
      </>
     );
    }
}

export default SaveIt