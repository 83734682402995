import React from 'react';
import styled from 'styled-components';
import { ContainerFull } from '../container/full'

const Logo = styled.div`
    width: 28px;
    height: 28px;
    
    background: #dedede;
    border-radius: 50%;
    position: relative;

    & img {
      width: 17px;
      height: 17px;
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;    
    }

    @media (max-width: 768px) {
        width: 23px;
        height: 23px;

    }
`;

const Item = styled.div`
    position: relative;
    border-bottom: ${ props => props.theme.lineOpacity };
    padding: 10px;
    &:hover {
        background: #0a0a0a;
    }

`;

const ContentLogo = styled.div`
    position: absolute;
    left: 20px;
    top: 20px;

    @media (max-width: 768px) {
        left: 15px;
        top: 15px;

    }
`;

const Content = styled.div`
    padding-top: 15px;
    padding-bottom: 15px;
`;

const Details = styled.div`
    font-size: 10px;
    color: ${ props => props.theme.mainGrey };
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
`;

const ItemTitle = styled.div`
    font-size: 23px;
    font-weight: 700;
    margin: 25px;
    color: ${ props => props.theme.whiteOpacity };
    text-align: center;
    & span {
        font-family: 'Inter';
        color: ${ props => props.theme.mainGrey };
        font-size: 11px;

    }
`;

export class ItemsBookmark extends React.Component {    

    getHostname(url) {
        let newUrl = new URL(url);
        return newUrl.hostname.replace('www.','');
    }

    getDomainName(url) {
        let newUrl = new URL(url);
        return newUrl.hostname.replace('www.', '').split(".")[0];
    }

    getDate(date) {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let format = new Date(date)
        let month = monthNames[format.getUTCMonth() + 1];
        let day = format.getUTCDate();
        let newFormat =  day + " " + month;

        return newFormat;
    }

    render() {
        return (
            <>
                <Item>
                    <ContainerFull>
                        <a href={this.props.link} target="_blank" rel="noopener noreferrer">
                            <Content>
                                <ContentLogo>
                                    <Logo><img src={ this.props.icon || "https://micro-zxccv.netlify.app/favicon.ico"} alt="faviconItem" /></Logo>
                                </ContentLogo>

                                <Details>{ this.getDate(this.props.created) }</Details>
                                <ItemTitle>{this.props.title} </ItemTitle>
                                <Details>{ this.getHostname(this.props.link) || "Error domain"}</Details>

                                {/* <ItemMore onClick={ (event) => { this.props.actionDeleteBoard(this.props.id) }} ><span className="material-symbols-rounded">more_vert</span></ItemMore> */}
                            </Content>
                        </a>
                    </ContainerFull>
                </Item>
            </>
        );
    }
}

export default ItemsBookmark;