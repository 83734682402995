import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const NavContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 54px;
  max-width: 90px;
  padding: 15px;

  border-right: ${ props => props.theme.lineOpacity };

  background: #000;
  z-index: 100;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  @media (max-width: 768px) {

    right: 0;
    bottom: 0;
    width: 100%;

    display: none;
  }
`;

const LogoSection = styled.div`
  margin-top: 20px;
  position: absolute;
  top: 20px;
`;

const Logo = styled.div`
    // width: 35px;
    // height: 35px;
    // margin-left: 15px;
    // margin-right: 15px;
    // background: #fff;
    // border-radius: 25%;
    // position: relative;

    & img {
      width: 24px;
      height: 24px;
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;    
  }
`;

const Circle = styled.span`
  border-radius: 50px;
  border: 2px solid #fff;
  width: 50px;
`;

// const Ul = styled.ul`
// // &:before {
// //   content: "";
// //   display: block;
// //   width: 100%;
// //   height: 2px;
// //   background: rgba(175,175,175,0.1);

// //   margin: 40px 0;
// // }

// `;

// const Li = styled.li`
//   text-align: center;
//     padding: 15px;
//     font-size: 17px;
//     font-weight: 700;
//     color: ${ props => props.theme.mainGrey };
    
//     transition: transform .4s;
//     &:hover {
//       transform: scale(1.2);
//     }

//     @media (max-width: 768px) {

//       font-size: 26px;

//     }
// `;

// const LiBottom = styled.li`
//     position: absolute;
//     bottom: 40px;
//     left: 0;
//     right: 0;
//     text-align: center;
//     padding: 15px;
//     font-size: 17px;
//     font-weight: 700;
//     color: ${ props => props.theme.mainGrey };
    
//     transition: transform .4s;
//     &:hover {
//       transform: scale(1.2);
//     }

//     // &:before {
//     //   content: "";
//     //   display: block;
//     //   width: 100%;
//     //   height: 2px;
//     //   background: rgba(175,175,175,0.1);
    
//     //   margin: 30px 0;
//     // }

//     @media (max-width: 768px) {

//       font-size: 26px;

//     }
// `;

class Nav extends React.Component {
    
    render() {
  
      return (
        <>

          <NavContainer>

            <LogoSection>
                {/* <Logo><img src="https://micro-zxccv.netlify.app/favicon.ico" alt="avatar" /></Logo> */}
                <NavLink end to="/workspaces">
                  <Logo><Circle></Circle></Logo>
                </NavLink>

            </LogoSection>

            {/* <Ul>
              <NavLink end className={(navData) => navData.isActive ? "is-active" : "" } to="/unassigned">
                <Li>
                <span className="material-symbols-rounded">home_storage</span>
                </Li>
              </NavLink>
              <NavLink end className={(navData) => navData.isActive ? "is-active" : "" } to="/workspaces">
                <Li>
                <span className="material-symbols-rounded">splitscreen</span>
                </Li>
              </NavLink>
              <NavLink end className={(navData) => navData.isActive ? "is-active" : "" } to="/workspaces">
                <Li>
                <span className="material-symbols-rounded">description</span>
                </Li>
              </NavLink>
              <NavLink className={(navData) => navData.isActive ? "is-active" : "" } to="/home">
                <Li>
                <span className="material-symbols-rounded">bookmark</span>
                </Li>
              </NavLink>

              <NavLink className={(navData) => navData.isActive ? "is-active" : "" } to="/create">
                <Li>
                <span className="material-symbols-rounded">search</span>
                </Li>
              </NavLink>

              <NavLink className={(navData) => navData.isActive ? "is-active" : "" } to="/settings">
                <LiBottom>
                <span className="material-symbols-rounded">settings</span>
                </LiBottom>
              </NavLink>
            </Ul> */}
          </NavContainer>
        </>
      );
    }
}
    
export default Nav;