import React from 'react';
import styled from 'styled-components';

const Item = styled.div`
    display: flex;
    align-items: center;
    padding: 7px;
`;

const Check = styled.div`

    border: 1px solid #454545;
    border-radius: 8px;
    padding: 10px;
`;


const CheckPosition = styled.div`
    margin-right: 12px;

`;

const CheckName = styled.input`
    background: none;
    color: ${ props => props.theme.whiteEditor };
    border: none;
    font-size: 14px;
    font-family: "Segoe UI", Arial, sans-serif;

`;

export class ToDo extends React.Component {

    render() {
        return (
            <>
                <Item>
                    <CheckPosition>
                        <Check></Check>
                    </CheckPosition>
                    
                    <CheckName value="example 1"></CheckName>
                </Item>
                <Item>
                    <CheckPosition>
                        <Check></Check>
                    </CheckPosition>
                    
                    <CheckName value="example 2"></CheckName>
                </Item>
                <Item>
                    <CheckPosition>
                        <Check></Check>
                    </CheckPosition>
                    
                    <CheckName value="example 3"></CheckName>
                </Item>
            </>
        );
    }
}

export default ToDo;