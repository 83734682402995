import React, { useState } from 'react';
import { Routes, Route, BrowserRouter, Navigate, useParams } from "react-router-dom";

import Login from './components/login';
import CreateProject from './components/createProject';
import BlockEditor from './components/blockEditor';

import Bookmarks from './components/bookmarks';
import Workspaces from './components/workspaces';
import Unassigned from './components/unassigned';
import SaveIt from './components/unassigned/saveIt';
import Plain from './components/plain';
import ViewMD from './components/plain/viewMD';
import Settings from './components/settings';

import { ThemeProvider } from 'styled-components';
import AppLayout from './components/layout';
import { GlobalStyle } from './theme/globalStyle'
import { Dark } from './theme/dark'

import { getToken } from './middleware/actions';

require('dotenv').config()

function App() {

  const [isLoggedIn] = useState(null);
  
  const PlainWrapper = () => {
    const { public_id } = useParams();
    return <Plain public_id={public_id} />;
  }; 
  
  const ViewMDWrapper = () => {
    const { public_id } = useParams();
    return <ViewMD public_id={public_id} />;
  }; 

  const WrapperSaveIt = () => {
    const { url } = useParams();
    return <SaveIt url={url} />;
  }; 

  const PrivateRoute = ({ isLoggedIn, children }) => {
    let token = getToken();

    if (!isLoggedIn && !token) {
      return <Navigate to="/auth" replace />;
    }
      return children;
  }

    return (
      <>
        <ThemeProvider theme = { Dark }>
          <BrowserRouter>
            <GlobalStyle />
            <Routes>
              <Route path="/auth" element={<Login />} />
              <Route path="/editor" element={<BlockEditor />} />
              <Route path="/plain/:public_id/edit" element={<PrivateRoute isLoggedIn={isLoggedIn}><PlainWrapper><Plain /></PlainWrapper></PrivateRoute>} />
              <Route path="/plain/:public_id" element={<PrivateRoute isLoggedIn={isLoggedIn}><ViewMDWrapper><ViewMD /></ViewMDWrapper></PrivateRoute>} />

              <Route path='/' element={(<AppLayout />)}>
                <Route path="/" element={<PrivateRoute isLoggedIn={isLoggedIn}><Workspaces /></PrivateRoute>} />
                <Route path="/create" element={<PrivateRoute isLoggedIn={isLoggedIn}><CreateProject /></PrivateRoute>} />
                <Route path="/bookmarks" element={<PrivateRoute isLoggedIn={isLoggedIn}><Bookmarks /></PrivateRoute>} />
                <Route path="/workspaces" element={<PrivateRoute isLoggedIn={isLoggedIn}><Workspaces /></PrivateRoute>} />
                <Route path="/unassigned" element={<PrivateRoute isLoggedIn={isLoggedIn}><Unassigned /></PrivateRoute>} />
                <Route path="/save/:url" element={<PrivateRoute isLoggedIn={isLoggedIn}><WrapperSaveIt><SaveIt /></WrapperSaveIt></PrivateRoute>} />
                <Route path="/settings" element={<PrivateRoute isLoggedIn={isLoggedIn}><Settings /></PrivateRoute>} />
              </Route>

              <Route path="*" element={<h1>404 - Not found</h1>} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </>
    );

}

export default App;