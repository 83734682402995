import { createGlobalStyle } from "styled-components"
// 050505 default
export const GlobalStyle = createGlobalStyle`

body {
    font-family: "Segoe UI", Arial, sans-serif;
    background: #000;
    color: rgba(255,255,255,0.87);
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none; 
    margin-bottom: 75px;

}

p {
  font-family: 'Libre Baskerville', serif;
  font-family: 'Poppins', sans-serif;
  font-family: "Segoe UI", Arial, sans-serif;
}

*::-webkit-scrollbar {
    display: none;
  }
* {
    outline: none;
    scrollbar-width: none;
}

// input {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
// }

a {
    text-decoration: none;
    color: #fff;
}

// ul {
//     list-style-type: none;
//     padding: 0;
// }

.is-active, .is-active li {
  color: #fff;
}

.mobileActive {
  display: block;
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }

  .hover-underline-animation:hover {
    color: #fff;
  }
  
  .hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #fff;
    transform-origin: bottom right;
    transition: transform 0.5s ease-out;
  }
  
  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .grey {
    color: #232323;
    font-size: 23px;
  }

  .activeFilter {
    color: #fff;
  }

  .activeBlob {
    color: #fff; !important;
    position: relative;
    
    ::after {
      content: '';
      display: inline-block;
      border-radius: 50%;
      margin-right: 5px;
      margin-left: 5px;
      height: 7px;
      width: 7px;
      
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      background: rgba(52, 172, 224, 1);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
    }

  }

  .circle-red {
    display: inline-block;
    border-radius: 50%;
    margin-right: 7px;
    margin-left: 5px;
    height: 7px;
    width: 7px;
    background: red;
  }

  .circle-green {
    display: inline-block;
    border-radius: 50%;
    margin-right: 7px;
    margin-left: 5px;
    height: 7px;
    width: 7px;
    background: #27ae60;
  }

  .circle-blue {
    display: inline-block;
    border-radius: 50%;
    margin-right: 7px;
    margin-left: 5px;
    height: 7px;
    width: 7px;
    background: rgba(52, 172, 224, 1);
  }

  .blob {
    display: inline-block;
    border-radius: 50%;
    margin-right: 13px;
    margin-left: 8px;
    height: 7px;
    width: 7px;

    background: rgba(52, 172, 224, 1);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
    animation: pulse-red 3s infinite;


  }

  @keyframes pulse-red {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }

    30% {
      transform: scale(0.97);
      box-shadow: 0 0 0 4px rgba(52, 172, 224, 0);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 7px rgba(52, 172, 224, 0);
    }
    
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 30px;
  }

  img {
    width: 100%;
    max-height: 100%;
  }
`;