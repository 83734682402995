import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Toolbar = styled.div`
    background: #020202;
    min-width: 280px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 2px 3px 0px rgba(7, 7, 7, 1);
    bottom: 20px;
    border-radius: 50px;

    padding: 5px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    padding: 5px 15px;
`;

const Item = styled.div`

    font-size: 13px;
    padding-left: 15px;
    cursor: pointer;
    & span {
        font-size: 16px;
    }
`;

const ItemMenu = styled.div`
    font-size: 13px;
    margin-right: auto;
    cursor: pointer;

`;

const BtnView = styled.div`
    border-radius: 25px;
    height: 10px;
    width: 10px;
    background: #e5c07b;
    
`;

export class ToolbarEditor extends React.Component {

    render() {
        return (
            <Toolbar>
                <Content>
                    <ItemMenu>Menu</ItemMenu>
                    <Item>Save</Item>

                    <Item><span className="material-symbols-rounded">content_copy</span></Item>
                    <Item>
                        <Link to={'./view'}>
                            <BtnView></BtnView>
                        </Link>
                    </Item>
                </Content>

            </Toolbar>
        );
    }
}

export default ToolbarEditor;