import React from 'react';
import { Outlet } from "react-router-dom";
import Nav from "../nav";
// import NavMobile from '../nav/mobile'
import styled from 'styled-components'

const Resp = styled.div`
    padding: 0 0px 0px 85px;
    //85
  @media (max-width: 768px) {
    padding: 0;
    //padding-top: 60px;

  }
`;

const AppLayout = () => {
    return <Resp style={{
        
    }}>
        <Nav />
        {/* <NavMobile /> */}
        <Outlet />
    </Resp>;
};

export default AppLayout;