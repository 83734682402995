import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ItemMore = styled.div`
    cursor: pointer;
    
    position: absolute;
    top: 20px;
    bottom: 0;
    right: 10px;
    color: ${ props => props.theme.lineGrey };

    & span {
        font-size: 19px;
    }


    @media (max-width: 768px) {
        top: 35%;
    }

    &:hover {
        color: #fff;
    }
`;

const Item = styled.div`
    position: relative;
    border-bottom: ${ props => props.theme.lineOpacity };
    display: flex;
    align-items: center;
    padding: 0 40px;

    @media (max-width: 768px) {
        //border-bottom: none;
        padding: 0 20px;
    }
`;

const Content = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;

    display: flex;
    align-items: center;
`;

const Icon = styled.div`
    font-size: 19px;
    margin-right: 10px;
    color: #7d7d7d


`;

const ItemTitle = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: ${ props => props.theme.whiteOpacityGlobal };

`;

export class ItemsDrag extends React.Component {    
    render() {
        return (
            <>
                <Item>
                        <Link to={'/'+ this.props.type +'/' + this.props.public_id}>
                            <Content>
                                <Icon className="material-symbols-rounded">menu_open</Icon>
                                <ItemTitle>{this.props.name}</ItemTitle>
                            </Content>                
                        </Link>
                        <Link to={'/'+ this.props.type +'/' + this.props.public_id + '/edit'}>
                            <ItemMore ><span className="material-symbols-rounded">drag_indicator</span></ItemMore>
                        </Link>
                </Item>
            </>
        );
    }
}

export default ItemsDrag;