import React from 'react';
import { ContainerFull } from '../container/full';
import Header from '../header'
import { TypeBar } from '../topBar/typeBar'
import { FadeIn } from '../../theme/fadeIn'
import Items from './items'

import { Link } from 'react-router-dom';
import StaticItems from './staticItems';

export class Workspaces extends React.Component {
    constructor(props) {
      super(props);

      this.state = {  
        showModal: false,
        info: '',
        items: '',
        filtered: '',
        loadingSpinner: false,
        showType: false
      };

      this.actionShowType = this.actionShowType.bind(this);
      this.fetchData = this.fetchData.bind(this);
    }
    
    fetchData() {
        fetch(`/v1/workspaces`, { method: 'GET', headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('token') } })
        .then(res => res.json())
        .then(json => this.setState({ loadingSpinner: false, items: json.items, filtered: json.data, info: json.info }))
        .catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', error.message);
        });;
    }


    componentDidMount() {
        document.title = "Workspaces | app.zxccv"
        this.fetchData();
    }

    actionShowType() {
        this.setState({ showType: !this.state.showType });
    }

    render() {
    return (
        <>

            <FadeIn>
                        <ContainerFull>
                            <Header name="Acme" actionShowType={ this.actionShowType } />

                            {
                                this.state.showType ?
                                (<TypeBar />)
                                :
                                (<></>)
                            }

                        <Link to={'/bookmarks'}>
                            <StaticItems name="Bookmarks" desc="Saved items without assignment" />             
                        </Link>          
                      
                            
                           {
                                (this.state.items || []).map((post, index) => (
                                    <Items
                                        key={index}
                                        name={post.name}
                                        desc={post.description} 
                                        type={post.type}
                                        public_id={post.public_id}
                                        id={post.id} 
                                    />
                                ))
                            }
                        </ContainerFull>

            </FadeIn>
      </>
     );
    }
}

export default Workspaces