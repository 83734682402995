import React from 'react';
import styled from 'styled-components';
import { FadeIn } from '../../theme/fadeIn'
import Header from '../header'
import { Container } from '../container';

const Section = styled.section`
    border-bottom: ${ props => props.theme.lineOpacity };
    padding-bottom: 50px;
    padding-top: 25px;
`;

const P = styled.p`
    font-size: 13px;
    color: ${ props => props.theme.mainGrey }
`;

const Input = styled.input`
    background: none;
    border-radius: 5px;
    border: ${ props => props.theme.lineOpacity };
    padding: 10px 15px;
    color: #a0a0a0;
    &:hover {
        background: #060606;
    }
`;

const BtnDeleteAccount = styled.button`
    background: none;
    border-radius: 5px;
    border: ${ props => props.theme.lineOpacity };
    padding: 10px 15px;
    margin-top: 10px;
    color: #d63031;
    border-color: #d63031;
    &:hover{
        background: #111;
        cursor: pointer;
    }
`;

const BtnExportData = styled.button`
    background: none;
    border-radius: 5px;
    border: ${ props => props.theme.lineOpacity };
    padding: 10px 15px;
    margin-right: 15px;
    margin-bottom: 10px;
    border-color: #333;
    color: #a0a0a0;
    &:hover{
        background: #111;
        cursor: pointer;
    }
`;


export class Settings extends React.Component {
    state = {
      dataUser: []
    };
  
    componentDidMount() {
        document.title = "Settings | zen.ns2k.me"
        fetch('/v1/user', { method: 'GET', headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('token')} })
        .then(res => res.json())
        .then(json => this.setState({ dataUser: json[0] }))
        .catch( (err) => {
            console.log('Error: ' + err);
        });
    }

    render() {
        return (
            <>
                <FadeIn>
                    <Container>
                        <Header name="Settings" desc="Here you will find all the information and settings of your account."/>

                        <Section>
                            <h2>User Info</h2>
                            <P>Sprawdź ustawienia swojego konta i dostosuj je do swoich potrzeb.</P>
                            <P>Username:</P>
                            <Input type="text" defaultValue={this.state.dataUser.username} ></Input>
                            <P>Password:</P>
                            <Input type="password" defaultValue="password"></Input>
                        </Section>

                        <Section>
                            <h2>E-mail</h2>
                            <P>Twój adres e-mail jest nam potrzebny w celu przypomnienia hasła w przypadku jego zgubienia. <br /> Czasami również poinformujemy cię o nowościach na naszej platformie.</P>
                            <P>e-mail:</P>
                            <Input type="text" defaultValue={this.state.dataUser.email}></Input>
                    </Section>

                        <Section>
                            <h2>Export</h2>
                            <P>Wyeksportuj swoje dane w formacie .json</P>
                            <BtnExportData>Export My Tasks</BtnExportData>
                            <BtnExportData>Export My Bookmarks</BtnExportData>
                            <BtnExportData>Export My Documents</BtnExportData>
                        </Section>

                        <Section>
                            <h2>Delete Account</h2>
                            <P>W każdej chwili możesz usunąć swoje konto kontaktując się z naszym supportem, pamiętaj jednak, że jest to proces nieodwracalny, a wszystkie twoje dane zostaną usuniętę bez możliwości ich przywrócenia!</P>
                            <BtnDeleteAccount>Delete Account</BtnDeleteAccount>
                        </Section>
                    </Container>
                </FadeIn>        
            </>
      );
    }
}

export default Settings;