import React from 'react';
import styled from 'styled-components';

const ItemMore = styled.div`
    cursor: pointer;
    
    position: absolute;
    top: 20px;
    bottom: 0;
    right: 10px;
    color: ${ props => props.theme.lineGrey };

    font-size: 9px;

    @media (max-width: 768px) {
        top: 35%;
    }

    &:hover {
        color: #fff;
    }
`;

const Item = styled.div`
    position: relative;
    border-bottom: ${ props => props.theme.lineOpacity };
    display: flex;
    align-items: center;
    padding: 0 40px;
    @media (max-width: 768px) {
        //border: none;
        padding: 0 20px;
    }
`;

const Content = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;

    display: flex;
    align-items: center;
`;

const ItemTitle = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: ${ props => props.theme.whiteOpacityGlobal };

`;

const Icon = styled.div`
    font-size: 19px;
    margin-right: 10px;
    color: #7d7d7d
`;

export class StaticItems extends React.Component {    
    render() {
        return (
            <>
                <Item>
                    <Content>
                        <Icon className="blob"></Icon>
                        {/* <Icon className="material-symbols-rounded">folder</Icon> */}

                        <ItemTitle>{this.props.name}</ItemTitle>
                    </Content>                
                    <ItemMore onClick={ (event) => { this.props.actionDeleteBoard(this.props.id) }} ><span className="material-symbols-rounded">chevron_right</span></ItemMore>
                </Item>

                <Item>
                    <Content>
                        {/* <Icon className="blob"></Icon> */}
                        <Icon className="material-symbols-rounded">folder</Icon>

                        <ItemTitle>Get Start</ItemTitle>
                    </Content>                
                    <ItemMore onClick={ (event) => { this.props.actionDeleteBoard(this.props.id) }} ><span className="material-symbols-rounded">chevron_right</span></ItemMore>
                </Item>


            </>
        );
    }
}

export default StaticItems;