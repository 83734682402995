
export const setToken = (token) => {
    localStorage.setItem("token", token);
    localStorage.setItem("lastLoginTime", new Date(Date.now()).getTime());
};

export const getToken = () => {

    if (localStorage.getItem("token") && localStorage.getItem("lastLoginTime")) {

      console.log(localStorage.getItem("token"));

      const now = new Date(Date.now()).getTime();
      const timeAllowed = 43200000; // 12h time allowed
      const timeSinceLastLogin = now - localStorage.getItem("lastLoginTime");
      if (timeSinceLastLogin < timeAllowed) {
          return localStorage.getItem("token");
      } else {
        deleteToken();
        return false
      }
    } else {
      return false

    }



};

export const deleteToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("lastLoginTime");
  }

export const checkAuth = (dupa) => {
      return fetch(process.env.REACT_APP_API_URL + '/user', {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getToken()
        }
      }).then((res) => {
        if (res.ok) {
            console.log("token poprawny");
            return dupa = true
        } else {
            console.log("token zjebał się");
            deleteToken();
            return dupa = false;
        }
     });
};