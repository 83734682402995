import React from 'react';
import styled from 'styled-components';
import { ContainerFull } from '../container/full'

const ItemMore = styled.span`
    cursor: pointer;
    
    position: absolute;
    top: 10px;
    right: 10px;
    color: ${ props => props.theme.lineGrey };
    font-size: 10px;

    &:hover {
        color: #fff;
    }
`;

// const Logo = styled.div`
//     width: 32px;
//     height: 32px;
//     margin-left: 15px;
//     margin-right: 15px;
//     background: #fff;
//     border-radius: 25%;
//     position: relative;

//     & img {
//       width: 21px;
//       height: 21px;
//       position: absolute;
//       margin: auto;
//       top: 0;
//       left: 0;
//       right: 0;
//       bottom: 0;    
//   }
// `;

const Item = styled.div`
    position: relative;
    &:hover {
        background: #0a0a0a;
    }
    border-bottom: ${ props => props.theme.lineOpacity };

`;

// const ContentLogo = styled.div`
//     margin-right: 5px;
//     margin-left: 10px;
// `;

const ContentText = styled.div`
`;

const Content = styled.div`
    padding-top: 15px;
    padding-bottom: 15px;

`;

// const Time = styled.span`
//     color: ${ props => props.theme.mainGrey };
//     font-weight: 400;
//     font-size: 13px;
// `;

const ContentImage = styled.div`
    margin-top: 5px;
    margin-bottom: 5px;
    height: 250px;
    max-width: 320px;

    & img {
        width: 100%;
        max-height: 250px; 
        border-radius: 6px;   
    }
    
    margin: 0 auto;
`;

// const ItemDetails = styled.div`
//     font-size: 14px;
//     font-weight: 500;
// `;

// const ItemDesc = styled.p`
//     margin: 0;
//     font-size: 11px;
//     color: ${ props => props.theme.mainGrey };
//     line-height: 19px;
//     height: 19px;
//     display: -webkit-box;
//     -webkit-line-clamp: 1;
//     -webkit-box-orient: vertical;
//     overflow: hidden;
//     text-overflow: ellipsis;
// `;

export class lastUpload extends React.Component {    

    getHostname(url) {
        let newUrl = new URL(url);
        return newUrl.hostname.replace('www.','');
    }

    getDomainName(url) {
        let newUrl = new URL(url);
        return newUrl.hostname.replace('www.', '').split(".")[0];
    }

    render() {
        return (
            <>
                <Item>
                    <ContainerFull>
                        <a href={this.props.link} target="_blank" rel="noopener noreferrer">
                            <Content>
                                {/* <ContentLogo>
                                    <Logo><img src="https://micro-zxccv.netlify.app/favicon.ico" alt="avatar" /></Logo>
                                </ContentLogo> */}

                                <ContentText>
                                    <ContentImage>
                                        <img alt="" src="https://picsum.photos/800/800" />    
                                    </ContentImage> 
                                    {/* <ItemDesc>{ this.props.desc || "Edit to add description..."}</ItemDesc>                             */}
                                </ContentText>

                                <ItemMore onClick={ (event) => { this.props.actionDeleteBoard(this.props.id) }} ><span className="material-symbols-rounded">more_vert</span></ItemMore>
  
                            </Content>

                        </a>
                    </ContainerFull>
                </Item>
            </>
        );
    }
}

export default lastUpload;