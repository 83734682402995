import React from 'react';
import styled from 'styled-components'
import { Container } from '../container'
import { FadeIn } from '../../theme/fadeIn'
import { setToken, getToken  } from '../../middleware/actions';

const Form = styled.form`
    margin-top: 20vh;
    text-align: center;
    & p {
        color: ${ props => props.theme.mainGrey };

    }
`;

const Input = styled.input`
    margin: 20px 0;
    padding: 15px;
    width: 250px;
    display: block;
    margin-right: auto;
    margin-left: auto;

    background: #121212;
    color: ${ props => props.theme.mainGrey };
    border: none;
    border-radius: 5px;
`;

const Submit = styled.button`
    padding: 15px 10px;
    width: 275px;

    background: #fff;
    color: ${ props => props.theme.mainBlack };
    font-weight: 700;
    border: ${ props => props.theme.lineOpacity };
    border-radius: 5px;
`;

const AuthFailed = styled.div`
    margin-top: 20px;
    color: red;
    font-size: 14px;
    text-align: center;
`;

export class Login extends React.Component {
    constructor(props) {
      super(props);

      this.handleSubmit = this.handleSubmit.bind(this);     
       
      this.state = {  
        showErrorAuth: false,
        showErrorAPI: false,
        loginName: "Login",
        submitDisable: false,
      };

    }

    handleSubmit(event) {
        event.preventDefault();
    
        this.setState({
          submitDisable: true,
          loginName: "Loading..."
        })
  
        fetch('/v1/auth', {
          method: "POST",
            headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Accept: 'application/json',
            },
            body: JSON.stringify({
            "username": event.target.username.value,
            "password": event.target.password.value
            }),
        })
        .then(resp => resp.json())
        .then(data => {
          if (data.success) {
            //const dataJwt = decode(data.token);

            setToken(data.token);
            window.location.href= "/";

          } else {
            console.log(data)
            this.setState({
              loginName: "Error",
              submitDisable: false,
              showErrorAuth: true
            })
  
          }

          this.setState({
            loginName: "Login",
            submitDisable: false
          })
        })
        .catch((err) =>{
          console.log(err)
          this.setState({
            showErrorAPI: true,
            loginName: "Error",
            submitDisable: false
          })
        })
    }

    checkAndRedirect() {
      if(getToken()) {
        window.location.href= "/";
      } 
    }
    
    componentDidMount() {
        document.title = "Login | app.zxccv";
        this.checkAndRedirect();
    }

    render() {
    return (
        <>
            <FadeIn>
                <Container>
                    <Form onSubmit={this.handleSubmit}>
                        <h1>Welcome back! <span role="img" aria-label='hello'>&#128075;</span></h1>
                        <p>Please enter your details.</p>

                        <Input id="username" name="login" type="text" placeholder="login" required />
                        <Input id="password" name="password" type="password" placeholder="password" required />
                        <Submit value={this.state.loginName} disabled={this.state.submitDisable} type="submit">Sing in</Submit>

                    </Form>
                    { this.state.showErrorAuth && <AuthFailed>Login or password is incorrect.</AuthFailed> }
                    { this.state.showErrorAPI && <AuthFailed>Error connect to API, please contact with administrator.</AuthFailed> }
                </Container>
            </FadeIn>
      </>
     );
    }
}

export default Login