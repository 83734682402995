import React from 'react';
import styled from 'styled-components';
import Loading from '../loading'

const Top = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-bottom: ${ props => props.theme.lineOpacity };
    padding: 15px;

    @media (max-width: 768px) {
        padding-top: 15px;
        padding-bottom: 15px;
        left: 0;
    }
`;

const ProjectName = styled.div`
    font-size: 32px;
    padding: 17px;
    font-weight: 800;
    padding: 5px;

    display: flex;
    align-items: center;
`;

const BtnToggleAdd = styled.span`
    font-size: 19px;
    cursor: pointer;
    color: ${ props => props.theme.mainGrey };
`

export class TopBar extends React.Component {

    render() {
        return (
            <Top>

                <ProjectName>
                    {
                        !this.props.loadingSpinner ?
                        (<>{this.props.name} </>)
                        :
                        (<Loading />)
                    }
                </ProjectName>

                <BtnToggleAdd onClick={ this.props.onBtnClick } className="material-symbols-rounded">new_window</BtnToggleAdd>
            </Top>
        );
    }
}

export default TopBar;