import React from 'react';
import styled from 'styled-components';

const Form = styled.div`
    position: fixed;
    flex-direction:row;
    bottom: 0;
    left: 85px;
    right: 0;
    background: #000;
    align-items: center;

    @media (max-width: 768px) {
        left: 0;
        bottom: 0;
      }
`;

const BtnSend = styled.span`
`;

const TextArea = styled.input`
    flex-grow:2;
    margin: 15px;
    margin-top: 10px;
    padding: 12px;
    font-size: 14px;
    width: calc(100% - 90px);
    height: 20px;
    color: ${ props => props.theme.mainGrey };
    background: #090909;
    border-radius: 15px;
    border: none;
`;

export class ChatFooter extends React.Component {    
    render() {
        return (
            <>
                <Form>
                    <TextArea placeholder="Type something"></TextArea>
                    <BtnSend><span className="material-symbols-rounded">send</span></BtnSend>
                </Form>
            </>
        );
    }
}

export default ChatFooter;