
import React from 'react';
import styled from 'styled-components'
import { ContainerEditor } from '../container/editor'

const Title = styled.input`
    margin-bottom: 40px;
    font-size: 52px;
    @media (max-width: 768px) {
        font-size: 36px;
    }
  
    width: 100%;
    font-weight: 700;
    color: ${ props => props.theme.whiteOpacityGlobal };
    background: transparent;
    border: none;
    font-family: "Segoe UI", Arial, sans-serif;
`;

const Editor = styled.div`
  margin-top: 120px;
`;

const Blocks = styled.div`

`;

const Block = styled.div`
    font-family: "Segoe UI", Arial, sans-serif;
    padding-top: 4px;
    padding-bottom: 4px;

    & * {
        display: inline-block;
    }

    // &:before {
    //     font-family: 'Material Symbols Rounded';
    //     content: "drag_indicator";
    //     padding-right: 5px;
    // }
`;

export class PlainEditor extends React.Component {

    constructor(props) {

      super(props);
    

      this.refBlocks = [];

      this.state = { 
        name: "Hello World",
        html: 'We`re looking for your document...',
        blocks: [
            {
                id: "sdf234vfsd",
                type: "text",
                content: "Hello, first paragraph"
            },   
            {
                id: "dsfsd43dfg",
                type: "text",
                content: "Hello, second paragraph"
            }
        ]          
      };


    }

    handleBlur(e) {
        e.preventDefault();

        console.log(e.currentTarget.textContent);
        let parentElement = e.target.parentElement;
        let index = this.state.blocks.indexOf.call(parentElement.children, e.target);
        const newSaveBlocks = this.state.blocks.slice() //copy the array
        //  console.log("probuje wyciagnac index: " + index)
        //  console.log(newSaveBlocks[index -1].content);
        newSaveBlocks[index].content = e.currentTarget.textContent;
        console.log("zapisałem to")
        // event.target.selectionEnd = position;    // Set the cursor back to the initial position.

        // this.setState({ blocks: newBlocks }) //another array
    }

    handleFocus(e) {
        e.target.classList.add("myClass");

    }

    keyPress(event) {

        // let parentElement = event.target.parentElement;
        // let index = this.state.blocks.indexOf.call(parentElement.children, event.target);

        
        // console.log("twoj index to: " + index)
        // console.log("nastepny element (focus): " + event.target.nextElementSibling?.id)
        const uid = function(){
            return Date.now().toString(36) + Math.random().toString(36).substr(2);
        }

                  // po kliknieciu dodaj nowy json do state (nie nowy div do html) i wyrenderuj na nowo blocks
        if(event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            event.stopPropagation();


            let nextElement = event.target.nextElementSibling?.id; // Could be any index
            
            let parentElement = event.target.parentElement;
            let index = this.state.blocks.indexOf.call(parentElement.children, event.target);
            console.log("twoj index to: " + index)
            console.log("nastepny element: " + nextElement)


            let genId = uid();

            let newTextBlock = {
                id: genId, //musi byc generowany logowo
                type: "text",
                content: " "
            } 
            index++
            
            // jesli nastepny element istnieje (nie jest ostatnim elementem)
            if (nextElement) {
                console.log("działa to")
                let nextBlock = [
                    // Items before the insertion point:
                    ...this.state.blocks.slice(0, index),
                    // New item:
                    newTextBlock,
                    // Items after the insertion point:
                    ...this.state.blocks.slice(index)
                  ];

                  console.log(nextBlock)

                this.setState({blocks: nextBlock}, () => { this.refBlocks[index].focus() }); // update state

            } else {
                // nastepny element nie istnieje i focus
                console.log("nastepny element nie istnieje, dodaje na koncu")
              this.setState({ blocks: [...this.state.blocks, newTextBlock ] }, () => { this.refBlocks[index].focus()  }) //another array

            }


        }

    }


    keyDown(event) {
        // let position = event.target.selectionStart; // Capture initial position

        let parentElement = event.target.parentElement;
        let index = this.state.blocks.indexOf.call(parentElement.children, event.target);
        // const newBlocks = this.state.blocks.slice() //copy the array
        // console.log("probuje wyciagnac index: " + index)
        // console.log(newBlocks[index -1].content);
        // newBlocks[index -1].content = event.target.innerHTML;

        // event.target.selectionEnd = position;    // Set the cursor back to the initial position.

        // this.setState({ blocks: newBlocks }) //another array


        //let currentTarget = event.target.id;
       // let nextTarget = event.target.nextElementSibling?.id;

        console.log(event)

        if(event.key === "ArrowUp" && index >= 1) {
            event.preventDefault();
            this.refBlocks[index - 1].focus()
        }

        if(event.key === "ArrowDown" && this.refBlocks.length > index) {
            event.preventDefault();
            this.refBlocks[index + 1].focus()
        } 
    }




    componentDidMount() {
      document.title = "Block Editor | ns2k.me";
      this.refBlocks[0] && this.refBlocks[0].focus();

    }
 

    render() {
        return (
            <> 
                <ContainerEditor>
                  <Editor>
                    <Title placeholder="Untitled" defaultValue={this.state.name}/>
                    <Blocks>

                        {
                            (this.state?.blocks || []).map((data, index) => (
                                <Block ref={ref => this.refBlocks[index] = ref} className={data.type} id={data.id} key={data.id} suppressContentEditableWarning="true" contentEditable="True" onKeyDown={this.keyDown.bind(this)} onBlur={this.handleBlur.bind(this)}  onKeyPress={this.keyPress.bind(this)}>
                                    { data.content }
                                </Block>
                
                            ))                    
                        }

                    </Blocks>
                  </Editor>
                </ContainerEditor>
            </>
        );
    }
}

export default PlainEditor