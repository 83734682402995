import React from 'react';
import styled from 'styled-components'
import { ContainerFull } from '../container/full'
import Items from './items'
import { FadeIn } from '../../theme/fadeIn'
import Loading from '../loading'
import { TopBar } from '../topBar'

const Content = styled.div`
    margin-top: 60px;
`;

export class Home extends React.Component {
    constructor(props) {
      super(props);

      this.changleFilter = this.changleFilter.bind(this);
      this.actionRight = this.actionRight.bind(this);

      this.state = {
        bookmarkInfo: '',
        bookmarkItems: '',
        currentFilter: 0,
        loadingSpinner: true
      };

      
    }

    changleFilter(e, index) {
        this.setState({ currentFilter: index })
    }

    fetchData() {
        fetch(`/v1/bookmark`, { method: 'GET', headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('token') } })
        .then(res => res.json())
        .then(json => this.setState({ loadingSpinner: false, bookmarkItems: json.items, filtered: json.items, bookmarkInfo: json.infoBookmark }))
        .catch(function(error) {
            console.log('There has been a problem with your fetch operation: ', error.message);
        });;
    }

    componentDidMount() {
        this.fetchData();
        document.title = "Unassiged | app.ns2k"
    }


    actionRight() {
        this.setState({ actionRight: !this.state.actionRight })
    }



    render() {
    return (
        <>
            <FadeIn>
                <ContainerFull>
                        { this.state.loadingSpinner && <Loading /> }

                        <Content>
                        <TopBar actionFrom="unassigned" dot={true} actionShowType={ this.actionShowType } title="Bookmarks" actionLeft="tune" selectType="false" />
                        {
                            (this.state.bookmarkItems || []).sort().map((post, index) => (
                                <Items
                                    key={index}
                                    icon={post.icon}
                                    title={post.title}
                                    desc={post.description} 
                                    created={post.created}
                                    link={post.link}
                                    public_id={post.public_id}
                                    id={post.id} 
                                />
                            ))
                        } 

                        </Content>

                </ContainerFull>
            </FadeIn>
      </>
     );
    }
}

export default Home