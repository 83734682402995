
import React from 'react';
import styled from 'styled-components'
import { ContainerEditor } from '../container/editor'
import { NavLink } from 'react-router-dom'
import ToolbarEditor from './toolbar';
import { marked } from "marked";
import TextareaAutosize from 'react-textarea-autosize';
import ToDo from './todo';

const Title = styled.textarea`
  font-size: 52px;
  @media (max-width: 768px) {
    font-size: 36px;
  }
  width: 100%;
  font-weight: 700;
  color: ${ props => props.theme.whiteOpacityGlobal };
  background: transparent;
  border: none;
  font-family: "Segoe UI", Arial, sans-serif;
  `;

const Editor = styled.div`
  margin-top: 120px;
`;

const Render = styled.div`
  & p {
    color: ${ props => props.theme.whiteEditor };
      font-size: 14px;
      line-height: 24px;
    }

    & h2 {
      margin-top: 40px;
    }

    & a {
      color: #3291ff;
    }

    & a:hover {
      text-decoration: underline;
    }

    & blockquote {
      background: #090909;
      margin-left: 20px;
      margin-right: 0;
      padding: 1px 20px;
      border-left: 4px solid #e74c3c;
    }

    & hr {
      border: 0;
      height: 1px;
      background: #222;
      margin: 60px 0;
    }

      // dlaczego nie wyglada bez nadania css - globalstyle
    & td, th {
      padding: 15px 20px;
      font-size: 14px;
    }

    & table {
      border-collapse: collapse;
      width: 100%;
    }

    & table, th, td {
      font-family: 'Roboto', sans-serif;
      border: 1px solid #212121;
      min-width: 120px;
    }

    & th {
      font-weight: 500;
      background: #090909;
    }
`;

const TitleMD = styled.h1`
    margin-bottom: 35px;
`

const BtnSave = styled.div`
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
    color: ${ props => props.theme.mainGrey };
    cursor: pointer;
    font-size: 12px;

    posistion: fixed;
    left: 50%;
`;

const BtnBack = styled.div`
    color: ${ props => props.theme.mainGrey };
    cursor: pointer;
    font-size: 16px;
`;

const Textarea = styled.textarea`
    margin-top: 75px;
    color: ${ props => props.theme.whiteEditor };
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
    font-size: 14px;
    line-height: 21px;
    min-height: 75vh;
    height: 100%;
    background: none;
    border: none;
    max-width: 1260px;
    width: 100%;
    resize: none;
`;

const ViewMD = styled.div`

`;

export class PlainEditor extends React.Component {


    constructor(props) {
      super(props);

      this.state = { 
        name: "Loading...",
        html: 'We`re looking for your document...',
        saveNow: false
      };


    }



    // check save click fucntion

    saveClick(content) {
        let self = this;
        this.setState({ saveNow: true })

        // console.log("zapisze do db: " + content);
        fetch(`/v1/plain/` + this.props.public_id, {
          method: 'PUT',
          body: JSON.stringify({
            name: this.state.name,
            content: content,
          }),
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('token')
          })
        })
        .then(function(response) {
          console.log(response.status);
            //sprawdz czy 201 jest odpowiedni a nie 200
          if (response.status === 201) {


            //set interval tu nie dziala poneiwaz powoduje jedynie zmiane na false, zostawic bez setinterval
            setInterval(() => self.setState({ saveNow: false}), 1000)
            window.location.replace("/plain/" + self.props.public_id);

            } else {
              window.alert("Save failed!");
            }
          return new Error("HTTP status " + response.status);
        })
        .catch(function(error) {
          window.alert("Save failed!");
          console.log('There has been a problem with your fetch operation: ', error.message);
        });;
    }

    handleChange = evt => {
        this.setState({html: evt.target.value});
      }
  
    onChange(event) {
        this.setState({html: event.target.value});
    }

    fetchData() {
        // fetch(`/v1/plain/` + this.props.public_id, { method: 'GET', headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('token') } })
        // .then(res => res.json())
        // .then(json => this.setState({ loadingSpinner: false, items: json.items, info: json.info }))
        // .catch(function(error) {
        //     console.log('There has been a problem with your fetch operation: ', error.message);
        // });;

        fetch(`/v1/plain/` + this.props.public_id, { method: 'GET', headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('token')} }).then(val => val.json())
        .then(rawContent => {
          this.setState({ name: rawContent.name })
          document.title = rawContent.name + " | ns2k.me";
          if (Object.keys(rawContent.content).length > 0) {
            this.setState({ html: rawContent.content })
            console.log("otrzymałem z db: " + rawContent.content )
          } else {
            this.setState({ html: ''  })
          }
        });
    }

    changeTitle(string) {
        let newName = prompt("Change name:", string);
  
        if (newName) {
          this.setState({name: newName}, () => { this.saveClick(this.state.html) });
        }
  
    }

    getMarkdownText() {

      let mdFile = this.state.html.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/,"");

      const renderer = new marked.Renderer();

      const linkRenderer = renderer.link;
      renderer.link = (href, title, text) => {
        const html = linkRenderer.call(renderer, href, title, text);
        return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
      };
      
      let raw = marked(mdFile, { renderer: renderer });
      return { __html: raw };
    }

    componentDidMount() {
      document.title = "Awesome Editor | ns2k.me";
      this.fetchData();
    }

    render() {
        return (
            <> 
                <ContainerEditor>
                  <Editor>
                    {/* <Title placeholder="Untitled" value={this.state.name}/> */}
                    {/* <TextareaAutosize spellCheck="false" onChange={this.handleChange} value={this.state.html} style={{ marginTop: "35px", marginBottom: "20px", width: "100%", border: "none", fontFamily: "Segoe UI, Arial, sans-serif", fontSize: "14px", lineHeight: "21px", background: "none", resize: "none", color: "#ccc" }}/> */}
                    {/* <ToDo /> */}
                    {/* <ToolbarEditor /> */}
                  </Editor>
                </ContainerEditor>
            </>
        );
    }
}

export default PlainEditor