import React from 'react';
import styled from 'styled-components';
import Loading from '../loading'
import { Link } from 'react-router-dom';
import TypeBar from './typeBar';

const Top = styled.div`
    position: fixed;
    left: 85px;
    right: 0;
    top: 0;
    z-index: 999;

    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-bottom: ${ props => props.theme.lineOpacity };


    @media (max-width: 768px) {
        padding-top: 5px;
        padding-bottom: 5px;
        left: 0;
    }
`;

const TopContent = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    padding: 12px;

    @media (max-width: 768px) {
        padding-top: 5px;
        padding-bottom: 5px;
        left: 0;
    }
`;

const ProjectDetails = styled.div`
    margin: 0 auto;
`;

const Name = styled.div`
    font-size: 15px;
    font-weight: 600;
    color: #ccc;
    padding-bottom: 2px;
    padding: 10px;
`;

const Description = styled.div`
    font-size: 10px;
    color: ${ props => props.theme.mainGrey };
`;

const BtnRight = styled.span`
    font-size: 19px;
    cursor: pointer;
    color: ${ props => props.theme.mainGrey };
`;

export class TopBar extends React.Component {
    constructor(props) {
        super(props);

        this.addLink = this.addLink.bind(this);


        this.state = {  
        };


      }

      addLink() {
        let self = this;
        let link;
        link = prompt("link:");
  
        if (link !== null && link !== '') {
          self.setState({ waitSave: true })
          fetch(`/v1/bookmark/add`, { method: 'POST', headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('token')}, body: JSON.stringify({ "url": link }) })
          .then(function(response) {
              if (response.status === 201 || 200) {
                //self.fetchData();
                window.location.reload(false);
                self.setState({ waitSave: false })
              } else {
                self.setState({ waitSave: false, errorAdd: true })
              }
          })
          .catch(function(error) {
              self.setState({ waitSave: false, errorAdd: true })
              console.log('There has been a problem with your fetch operation: ', error.message);
          });;
        }
      }



    render() {

        return (
            <>
            <Top>
                <TopContent>

                {/* <BtnRight className="material-symbols-rounded">arrow_back_ios_new</BtnRight> */}


                <ProjectDetails>
                    {
                        !this.props.loadingSpinner ?
                        (<>
                            <Name>{this.props.title}</Name>
                        </>)
                        :
                        (<Loading />)
                    }
                </ProjectDetails>


                {
                    this.props.actionFrom === "unassigned" ?
                    (
                        <BtnRight onClick={this.addLink} className="material-symbols-rounded">ios_share</BtnRight>
                    ) :
                    (
                        <Link to={'/create'}>
                            <BtnRight className="material-symbols-rounded">add</BtnRight>
                        </Link>
                    )
                }
                </TopContent>
                {/* <TypeBar /> */}
            </Top>
            </>
        );
    }
}

export default TopBar;