import React from 'react';
import styled from 'styled-components'
import { ContainerEditor } from '../container/editor'
import { marked } from "marked";
import { Link } from 'react-router-dom';

const BackToEdit = styled.div`
    position: fixed;
    top: 20px;
    right: 20px;

    & pre {
      color: #444;
      font-family: monospace;
      font-size: 11px;

      &:hover {
        color: #ccc;
      }
    }



`

const Content = styled.div`
  max-width: 720px;
  margin: auto;
  margin-top: 90px;

  & h1, h2, h3, h4, h5 {
    color: ${ props => props.theme.whiteOpacityGlobal };

    // font-family: 'Inter', sans-serif !important;
  }
`;

const Render = styled.div`
  & p {
      // font-family: 'Inter', sans-serif;
      color: ${ props => props.theme.whiteEditor };
      font-size: 14px;
      line-height: 23px;
    }

    & h2 {
      margin-top: 40px;
    }

    & a {
      color: #3291ff;
    }

    & a:hover {
      text-decoration: underline;
    }

    & blockquote {
      background: #090909;
      margin-left: 20px;
      margin-right: 0;
      padding: 1px 20px;
      border-left: 4px solid #e74c3c;
    }

    & hr {
      border: 0;
      height: 1px;
      background: #222;
      margin: 60px 0;
    }

      // dlaczego nie wyglada bez nadania css - globalstyle
    & td, th {
      padding: 15px 20px;
      font-size: 14px;
    }

    & table {
      border-collapse: collapse;
      width: 100%;
    }

    & table, th, td {
      font-family: 'Roboto', sans-serif;
      border: 1px solid #212121;
      min-width: 120px;
    }

    & th {
      font-weight: 500;
      background: #090909;
    }
`;

const TitleMD = styled.h1`
    margin-bottom: 35px;
`

const Footer = styled.div`
    color: ${ props => props.theme.lineGrey };
    margin-top: 100px;
    font-size: 12px;
    font-family: monospace;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: #ccc;
    }
`;


export class ViewMD extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = { 
        name: "Loading...",
        html: 'We`re looking for your document...',
        saveNow: false
      };
    }

    // check save click fucntion

    saveClick(content) {
        let self = this;
        this.setState({ saveNow: true })
  
        // console.log("zapisze do db: " + content);
        fetch(`/v1/plain/` + this.props.public_id, {
          method: 'PUT',
          body: JSON.stringify({
            name: this.state.name,
            content: content,
          }),
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('token')
          })
        })
        .then(function(response) {
          console.log(response.status);
            //sprawdz czy 201 jest odpowiedni a nie 200
          if (response.status === 201) {
            //set interval tu nie dziala poneiwaz powoduje jedynie zmiane na false, zostawic bez setinterval
            setInterval(() => self.setState({ saveNow: false}), 1000)
  
            } else {
              window.alert("Save failed!");
            }
          return new Error("HTTP status " + response.status);
        })
        .catch(function(error) {
          window.alert("Save failed!");
          console.log('There has been a problem with your fetch operation: ', error.message);
        });;
    }

    handleChange = evt => {
        this.setState({html: evt.target.value});
      }
  
    onChange(event) {
        this.setState({html: event.target.value});
    }

    fetchData() {
        // fetch(`/v1/plain/` + this.props.public_id, { method: 'GET', headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('token') } })
        // .then(res => res.json())
        // .then(json => this.setState({ loadingSpinner: false, items: json.items, info: json.info }))
        // .catch(function(error) {
        //     console.log('There has been a problem with your fetch operation: ', error.message);
        // });;

        fetch(`/v1/plain/` + this.props.public_id, { method: 'GET', headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': "Bearer " + localStorage.getItem('token')} }).then(val => val.json())
        .then(rawContent => {
          this.setState({ name: rawContent.name })
          document.title = rawContent.name + " | ns2k.me";
          if (Object.keys(rawContent.content).length > 0) {
            this.setState({ html: rawContent.content })
            console.log("otrzymałem z db: " + rawContent.content )
          } else {
            this.setState({ html: ''  })
          }
        });
    }

    getMarkdownText() {

      let mdFile = this.state.html.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/,"");

      const renderer = new marked.Renderer();

      const linkRenderer = renderer.link;
      renderer.link = (href, title, text) => {
        const html = linkRenderer.call(renderer, href, title, text);
        return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
      };
      
      let raw = marked(mdFile, { renderer: renderer });
      return { __html: raw };
    }

    componentDidMount() {
        document.title = "Awesome Editor | ns2k.me";
        document.body.style = "background: #000;";
        this.fetchData();
    }

    render() {
        return (
            <>              
            <ContainerEditor>

              <BackToEdit>
                <Link to={'/plain/' + this.props.public_id + '/edit'}>
                  <pre>(src)</pre>
                </Link>
              </BackToEdit> 
              <Content>
                <TitleMD>{ this.state?.name }</TitleMD>
                <Render dangerouslySetInnerHTML={this.getMarkdownText()} ></Render>
                <Footer>{ this.state?.name } | Powered by Acme</Footer>
              </Content>

            </ContainerEditor>  
            </>
        );
    }
}

export default ViewMD