import React from 'react';
import styled from 'styled-components'

const LoadingText = styled.div`
    margin-top: 120px;

    position: absolute;
    left: 0;
    right: 0;


    padding: 15px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    border-radius: 3px;
    &:after {
      content: '.';
      font-size: 17px;
      animation: dots 2s steps(5, end) infinite;  
    }
    
    @keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  40% {
    color: white;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
      .25em 0 0 white,
      .5em 0 0 white;}}
`;

export class Loading extends React.Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() {
        return(
            <>                
              <LoadingText>Loading</LoadingText>
            </>
        );
    }
}

export default Loading;