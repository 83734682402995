import React from 'react';
import styled from 'styled-components'
import { Container } from '../container'
import { FadeIn } from '../../theme/fadeIn'
import { TopBar } from '../topBar'

const Form = styled.form`
    margin-top: 100px;
`;

const Label = styled.label`
    font-size: 13px;
    color: ${ props => props.theme.mainGrey };
    font-weight: 500;
`;

const Input = styled.input`
    margin-bottom: 25px; 
    background: transparent;
    font-size: 23px;
    color: #fff;
    border: none;
    display: block;

    &::placeholder {
        opacity: 1;
        color: #fff;
    }
`;

const Textarea = styled.textarea`
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    background: transparent;
    border: none;
    color: ${ props => props.theme.mainGrey };
    display: block;
    min-height: 120px;
    width: 90%;
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    

    &::placeholder {
        opacity: 1;
        color: #fff;
    }
`;

const Submit = styled.button`
    background: #fff;
    display: block;
    color: ${ props => props.theme.mainBlack };

    font-size: 13px;
    font-weight: 700;
    cursor: pointer;

    border: none;
    border-radius: 5%;
    padding: 18px;

    -webkit-box-shadow: 8px 8px 24px 0px rgba(20, 20, 20, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(20, 20, 20, 1);
    box-shadow: 8px 8px 24px 0px rgba(20, 20, 20, 1);

    @media (max-width: 768px) {
        left: 10px;
        right: 10px;
        border-radius: 0;
        position: fixed;
        bottom: 100px;
    }
`;

export class CreateProject extends React.Component {
    constructor(props) {
      super(props);

      this.handleSubmit = this.handleSubmit.bind(this);     

    }
    componentDidMount() {
        document.title = "Create new project  | app.zxccv"
    }

    handleSubmit(event) {
        event.preventDefault();
    
        // this.setState({
        //   submitDisable: true,
        //   loginName: "Loading..."
        // })
  
        fetch('/v1/projects', {
          method: "POST",
            headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('token')
            },
            body: JSON.stringify({
            "title": event.target.title.value,
            "description": event.target.description.value
            }),
        })
        .then(resp => {

            console.log(resp)
            console.log(resp.status)

          if (resp.status === 201) {

            window.location.href= "/productivity";


          } else {
            console.log("wyslanie formularza modal nie powiodło się")
  
          }

        //   this.setState({
        //     loginName: "Login",
        //     submitDisable: false
        //   })
        })
        .catch((err) =>{
          console.log(err)
        //   this.setState({
        //     showErrorAPI: true,
        //     loginName: "Error",
        //     submitDisable: false
        //   })
        })
    }

    render() {
    return (
        <>
            <TopBar title="Create new" dot={false} />

            <FadeIn>
                <Container>
                        <Form onSubmit={this.handleSubmit}>
                            <Label htmlFor="title">Title </Label>
                            <Input type="text" id="title" placeholder="Change the world" required />
                            
                            <Label htmlFor="description">Description </Label>
                            <Textarea type="text" id="description" placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In tortor ligula, venenatis at tincidunt ac, porta non velit. Pellentesque nisi ex, sollicitudin vitae magna ut, congue semper dolor." required></Textarea>

                            <Submit type="submit">Create</Submit>
                        </Form>                  

                </Container>
            </FadeIn>
      </>
     );
    }
}

export default CreateProject