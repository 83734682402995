import React from 'react';
import styled from 'styled-components';

const TopSeconds = styled.div`

`;

const Select = styled.div`
    display: flex;
`;

const Option = styled.div`
    font-size: 13px;
    font-weight: 500;
    width: 50%;
    text-align: center;
    padding-bottom: 10px;

    &:nth-child(1) {
        border-bottom: 2px solid rgba(52, 172, 224, 1);
    }

`;

export class TypeBar extends React.Component {

    render() {

        return (
            <>
            <TopSeconds>

                        <Select name="typeBoard" id="typeBoard">
                            <Option value="tasks">Flow</Option>
                            <Option value="notes">Attachments</Option>
                        </Select>

            </TopSeconds>


            
            </>
        );
    }
}

export default TypeBar;